/**
 * Home Component
 */

import { React, Component } from "react";
import { Container, Row, Col, InputGroup, FormControl, Button, Card } from "react-bootstrap";
import axios from "axios";
import { findIndex } from 'lodash';
import "./scss/Home.scss";

export default class Home extends Component {

    constructor(...args) {
        super(...args);

        this.state = {
            breed: '',
            breeds: [],
            cats: [],
            loading: false,
            no_more_data: false,
            domready: false
        }
    }

    render() {
        return (
            <div className="Home">
                <Container>
                    <h1>Cat Browser</h1>
                    <Row>
                        <Col className="mb-3" md={{span: 4, offset: 4}} sm={{span: 6, offset: 3}} xs={12}>
                            <InputGroup className="mt-3 mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Breed</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl as="select" value={this.state.breed} onChange={(e) => { this.selectCatBreed(e.target.value); }}>
                                    <option value="">Select breed</option>
                                    {
                                        this.state.breeds.map(({ id, name }) => (
                                            <option key={id} value={id}>{name}</option>
                                        ))
                                    }
                                </FormControl>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        {(
                            !this.state.cats.length ?
                                <Col className="mt-3 mb-3" xs={12}>
                                    <p className="text-center">No cats available</p>
                                </Col>
                            :
                            this.state.cats.map(({ id, url }, i) => (
                                <Col className="mb-4" md={3} sm={6} xs={12} key={i}>
                                    <Card>
                                        <Card.Img variant="top" src={url} />
                                        <Card.Body>
                                            <a className="btn btn-primary btn-block" href={'/' + id}>View details</a>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        )}
                    </Row>
                    {(
                        this.state.no_more_data ? '' :
                        <Row>
                            <Col className="mt-4 mb4" md={{span: 4, offset: 4}} sm={{span: 6, offset: 3}} xs={12}>
                                <Button variant="success" disabled={!this.state.breed || this.state.loading} size="lg" block onClick={() => {this.loadImagesOfCats(this.state.page + 1)}}>
                                    { this.state.loading ? 'Loading cats...' : 'Load more' }
                                </Button>
                            </Col>
                        </Row>
                    )}
                    
                </Container>
            </div>
        ); 
    }

    componentDidMount() {
        this.getAllBreedsOfCats();
    }

    /**
     * Get All Breeds of Cats
     */
    getAllBreedsOfCats() {
        axios({
            method: 'get',
            url: 'https://api.thecatapi.com/v1/breeds',
            params: {}
        })
        .then(({ data }) => {
            this.setState({
                breeds: data,
                domready: true
            });

            // check if there is an existing query parameter in the url and then get the value
            let search = window.location.search;
            search = search.replace('?','');
            let searchArr = !search ? null : search.split("=");
            const breed = !searchArr ? null : searchArr[1];

            if (breed) {
                this.selectCatBreed(breed);
            }
        })
        .catch(() => {
            alert('Apologies but we could not load new cats for you at this time! Miau!');
        });
    }

    /**
     * Select Cat Breed
     * 
     * @param breed - the selected breed from the select element
     */
    selectCatBreed(breed) {
        this.setState({
            breed,
            cats: []
        });

        if (breed) {
            this.loadImagesOfCats(1, breed);
        }
    }

    /**
     * Load Images of Cats based from the selected breed
     * 
     * @param page - the page of the group of records
     * @param breed - the select breed of cat
     */
    loadImagesOfCats(page, breed = this.state.breed) {
        axios({
            method: 'get',
            url: 'https://api.thecatapi.com/v1/images/search',
            params: {
                page: page,
                limit: 10,
                breed_id: breed
            }
        })
        .then(({ data }) => {
            const cats = [];
            
            data.forEach((cat) => {
                if (findIndex(this.state.cats, ({ id }) => (id === cat.id)) < 0) {
                    cats.push(cat);
                }
            })

            this.setState({
                loading: false,
                cats: this.state.cats.concat(cats),
                no_more_data: (cats.length === 0)
            });
        })
        .catch(() => {
            alert('Apologies but we could not load new cats for you at this time! Miau!');
        });

        this.setState({
            loading: true,
            page
        });
    }

}