/**
 * Details component
 */

import { React, Component } from "react";
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Card } from "react-bootstrap";
import axios from "axios";

import "./scss/Details.scss";

export default class Details extends Component {

    constructor(...args) {
        super(...args);
        this.state = {
            cat: {},
            domready: false
        }
    }

    render() {
        if (this.state.domready && !this.state.cat.id) {
            return (
                <Redirect to="/" />
            );
        }

        if (!this.state.domready) {
            return (
                <div className="Details">
                    <Container>
                        <Row>
                            <Col className="mt-5">
                                <h5 className="text-center">Loading...</h5>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }

        const breed = this.state.cat.breeds[0];

        return (
            <div className="Details">
                <Container>
                    <Row>
                        <Col md={{span: 8, offset: 2}} sm={{span: 10, offset: 1}} xs={12}>
                            <Card>
                                <Card.Header>
                                    <a className="btn btn-primary" href={'/?breed=' + breed.id}>Back</a>
                                </Card.Header>
                                <Card.Img src={this.state.cat.url} />
                                <Card.Body>
                                    <h4>{breed.name}</h4>
                                    <h5>Origin: {breed.origin}</h5>
                                    <h6>{breed.temperament}</h6>
                                    <p>{breed.description}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    
                </Container>
            </div>
        );
    }

    componentDidMount() {
        const pathname = window.location.pathname;
        axios({
            method: 'get',
            url: 'https://api.thecatapi.com/v1/images' + pathname,
            params: {}
        })
        .then(({ data }) => {
            this.setState({
                cat: data,
                domready: true
            });
        })
        .catch(() => {
            alert('Apologies but we could not load new cats for you at this time! Miau!');
        });
    }

}