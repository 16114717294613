/**
 * Setup all your routes here
 */
import { React } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "../components/Home/Home";
import Details from "../components/Details/Details";

export default function Routes() {
    return (
        <Router>
            <Route path="/" exact component={Home} />
            <Route path="/:id" exact component={Details} />
        </Router>
    )
}